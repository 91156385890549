import { Tab, Transition } from '@headlessui/react'
import {
  ArrowRightCircleIcon,
  ChartBarSquareIcon,
  CheckIcon,
  ChevronRightIcon,
  CommandLineIcon,
  GlobeAltIcon,
  ScaleIcon
} from '@heroicons/react/24/outline'
import { json } from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'
import classNames from 'classnames'
import { z } from 'zod'
import Container from '~/components/container'
import FaqSection from '~/components/faq-section'
import { Footer } from '~/components/footer'
import { Header } from '~/components/header'
import { LogoCarousel } from '~/components/logo-carousel'
import type { ITestimonials } from '~/components/testimonial-carousel'
import TestimonialCarousel from '~/components/testimonial-carousel'
import USPSection from '~/components/usp-section'
import { SCHEDULE_BUILDER_FE_URL } from '~/constants/env-variables.server'
import { useSliderInterval } from '~/hooks/useSliderInterval'
import Amazon from '~/images/amazon.webp'
import Bacardi from '~/images/bacardi.webp'
import Bosch from '~/images/bosch.webp'
import Bulgari from '~/images/bulgari.webp'
import PortsSectionImg from '~/images/dc-ports-screenshot.webp'
import FlyingTiger from '~/images/flying-tiger.webp'
import GLECLogo from '~/images/GLEC-logo.webp'
import Heineken from '~/images/heineken.webp'
import HomeCargoOwnersImg from '~/images/home-cargo-owners.webp'
import amsterdamLogo from '~/images/home-carousel/amsterdam-home-page-logo.webp'
import antwerpLogo from '~/images/home-carousel/antwerp-home-page-logo.webp'
import baltimoreLogo from '~/images/home-carousel/baltimore-home-page-logo.webp'
import cmacgmLogo from '~/images/home-carousel/cmacgm-home-page-logo.webp'
import deltaLogo from '~/images/home-carousel/delta-home-page-logo.webp'
import duisportLogo from '~/images/home-carousel/duisport-home-page-logo.webp'
import gothenburgLogo from '~/images/home-carousel/gothenburg-home-page-logo.webp'
import haegerLogo from '~/images/home-carousel/haeger-home-page-logo.webp'
import hapagLogo from '~/images/home-carousel/hapag-home-page-logo.webp'
import kansasLogo from '~/images/home-carousel/kansas-home-page-logo.webp'
import mscLogo from '~/images/home-carousel/msc-home-page-logo.webp'
import navilandLogo from '~/images/home-carousel/naviland-home-page-logo.webp'
import porLogo from '~/images/home-carousel/por-home-page-logo.webp'
import samskipLogo from '~/images/home-carousel/samskip-home-page-logo.webp'
import seatradeLogo from '~/images/home-carousel/seatrade-home-page-logo.webp'
import shandongLogo from '~/images/home-carousel/shandong-home-page-logo.webp'
import soharLogo from '~/images/home-carousel/sohar-home-page-logo.webp'
import strasbourgLogo from '~/images/home-carousel/strasbourg-home-page-logo.webp'
import HomeCarriersImg from '~/images/home-carriers-operators.webp'
import CarriersSectionImg from '~/images/home-carriers-section-image.webp'
import Slider3Img from '~/images/home-connect-to-api-slider3.webp'
import Slider2Img from '~/images/home-optimise-portfolio-slider2.webp'
import HeroBgImg from '~/images/home-page-hero.webp'
import HomePortsImg from '~/images/home-ports-terminals.webp'
import Slider1Img from '~/images/home-search-globally-slider1.webp'
import Intersnack from '~/images/intersnack.webp'
import IsoCertificationLogo from '~/images/iso-logo.webp'
import Lotus from '~/images/lotus.webp'
import Mars from '~/images/mars.webp'
import Nestle from '~/images/nestle.webp'
import NeverStopOptimisingImg from '~/images/never-stop-optimising-supply-chain.webp'
import Nike from '~/images/nike.webp'
import Nokia from '~/images/nokia.webp'
import Pepsico from '~/images/pepsico.webp'
import RabelinkLogo from '~/images/rabelink-logo.webp'
import RabelinkTestimonialImg from '~/images/rabelink-testimonial.webp'
import RoyalIngredientsTestimonialImg from '~/images/royal-ingredients-testimonial.webp'
import RoyalIngredientsImg from '~/images/royal-ingredients.webp'
import Samsung from '~/images/samsung.webp'
import Scania from '~/images/scania.webp'
import SchavemakerLogo from '~/images/schavemaker-logo.webp'
import SchavemakerTestimonialImg from '~/images/schavemaker-testimonial.webp'
import SIGLogo from '~/images/SIG-logo.webp'
import SFCLogo from '~/images/smart-freight-centre-logo.webp'
import Stellantis from '~/images/stellantis.webp'
import Tesla from '~/images/tesla.webp'
import TiffanyCo from '~/images/tiffanyco.webp'
import CargoOwnersSectionImg from '~/images/tradelanes-optimizer.webp'
import Veja from '~/images/veja.webp'
import Volvo from '~/images/volvo.webp'
import statsSchema from '~/schemas/stats.schema'
import { getUserProfile } from '~/services/auth.server'
import { optimizerIntegrationEnabled } from '~/services/optimizer-integration-enabled'
import { getRoutescannerApiConfig, RoutescannerApi } from '~/services/routescanner-api.server'
import numberFormatter from '~/utils/number-formatter'
import { SearchVoyagesFormV2 } from './fullstack-components.search-voyages-v2'
import type { LoaderFunctionArgs } from '@remix-run/node'
import type { SitemapFunction } from 'remix-sitemap'

export const sitemap: SitemapFunction = () => ({
  exclude: true
})

const usersLogoList = [
  {
    url: Tesla,
    name: 'Tesla Logo'
  },
  { url: TiffanyCo, name: 'TiffanyCo Logo' },
  { url: Veja, name: 'Veja Logo' },
  { url: Volvo, name: 'Volvo Logo' },
  { url: Samsung, name: 'Samsung Logo' },
  { url: Scania, name: 'Scania Logo' },
  { url: Stellantis, name: 'Stellantis Logo' },
  { url: Nike, name: 'Nike Logo' },
  { url: Nokia, name: 'Nokia Logo' },
  { url: Pepsico, name: 'Pepsico Logo' },
  // { url: Loreal, name: 'Loreal Logo' },
  { url: Nestle, name: 'Nestle Logo' },
  { url: Mars, name: 'Mars Logo' },
  { url: Intersnack, name: 'Intersnack Logo' },
  { url: Amazon, name: 'Amazon Logo' },
  { url: Bosch, name: 'Bosch Logo' },
  { url: Bulgari, name: 'Bulgari Logo' },
  { url: FlyingTiger, name: 'FlyingTiger Logo' },
  { url: Heineken, name: 'Heineken Logo' },
  { url: Bacardi, name: 'Bacardi Logo' },
  { url: Lotus, name: 'Lotus Logo' }
]

const operatorsLogoList = [
  { url: mscLogo, name: 'MSC logo' },
  { url: porLogo, name: 'Port of Rotterdam logo' },
  { url: hapagLogo, name: 'Hapag-Lloyd logo' },
  { url: shandongLogo, name: 'Shandong Qingdao port Logo' },
  { url: soharLogo, name: 'Port of Sohar logo' },
  { url: haegerLogo, name: 'Haeger Schmidt Logistics logo' },
  { url: navilandLogo, name: 'Naviland Cargo logo' },
  { url: baltimoreLogo, name: 'Port of Baltimore logo' },
  { url: samskipLogo, name: 'Samskip logo' },
  { url: gothenburgLogo, name: 'Port of Gothenburg logo' },
  { url: seatradeLogo, name: 'Seatrade logo' },
  { url: duisportLogo, name: 'Duisport logo' },
  { url: cmacgmLogo, name: 'CMA CGM logo' },
  { url: deltaLogo, name: 'Deltaport logo' },
  { url: kansasLogo, name: 'Kansas City Southern Logo' },
  { url: amsterdamLogo, name: 'Port of Amsterdam logo' },
  { url: strasbourgLogo, name: 'Port Strasbourg logo' },
  { url: antwerpLogo, name: 'Port of Antwerp logo' }
]

const uspContent = [
  {
    headingText: 'Report on carbon footprint',
    icon: <GlobeAltIcon className="flex h-8 w-8 items-center gap-2 text-brand-tertiary-700" />,
    order: 1,
    paragraphText: `The only platform to combine worldwide container logistics with carbon emissions reporting. Gain easy and user-friendly insights in your carbon footprint or that of your clients.`
  },
  {
    headingText: 'Find better container trade lanes',
    icon: <ScaleIcon className="flex h-8 w-8 items-center gap-2 text-brand-tertiary-700" />,
    order: 2,
    paragraphText: `Compare lead time, emissions and costs. Schedules are directly sourced from operators themselves. SIG accreditation is the proof of our neutrality.`
  },
  {
    headingText: 'Visualize your network',
    icon: <ChartBarSquareIcon className="flex h-8 w-8 items-center gap-2 text-brand-tertiary-700" />,
    order: 3,
    paragraphText: `Our uniquely developed intermodal container routing engine is covering Sea, Rail, Barge & Truck. Present it to the world on your own website or communications.`
  }
]

const howRoutescannerHelpsContent = [
  {
    labelText: 'Cargo Owners & Forwarders',
    heading: 'Find better trade lanes',
    description: 'Report your emission, rethink your supply chain and reduce inefficiencies through continuous supply chain optimization.',
    img: HomeCargoOwnersImg,
    id: 1,
    link: '/roles/cargo-owners-freight-forwarders'
  },
  {
    labelText: 'Carriers & Operators',
    heading: 'Connect supply & demand',
    description: 'Appear as the best option for cargo owners and freight forwarders searching for door-to-door container transport solutions.',
    img: HomeCarriersImg,
    id: 2,
    link: '/roles/carriers-and-operators'
  },
  {
    labelText: 'Ports & Terminals',
    heading: 'Promote intermodal container networks',
    description: 'Visualize your network on Routescanner on your own website and become an ambassador for your port community.',
    img: HomePortsImg,
    id: 3,
    link: '/features/direct-connections',
    linkText: 'Learn more'
  }
]

const testimonials: ITestimonials[] = [
  {
    designation: 'Logistics Development Manager at Schavemaker',
    givenBy: 'Arnoud Paans',
    id: 0,
    imageURL: SchavemakerTestimonialImg,
    reviewerImg: SchavemakerLogo,
    imageStyles: 'object-cover object-left rounded-md',
    text: `"Along with competitive pricing and prompt lead times, we can now offer clear insights into carbon emissions to those considering Schavemaker.”`,
    hasReviewerImgBg: false
  },
  {
    designation: 'Risk Information Manager at Royal Ingredients Group',
    givenBy: 'Kevin Bouwes',
    id: 1,
    imageURL: RoyalIngredientsTestimonialImg,
    reviewerImg: RoyalIngredientsImg,
    imageStyles: 'rounded-md',
    text: `"Routescanner accurately analyzed our container shipment portfolio and provided insights on the emissions on each individual trade lane. This improved our understanding of the emissions across our portfolio and allowed for more strategic decision making to reduce these going forward.”`,
    hasReviewerImgBg: false
  },
  {
    designation: 'Manager Air & Ocean at Rabelink Logistics',
    givenBy: 'Jeroen Verheij',
    id: 2,
    imageURL: RabelinkTestimonialImg,
    reviewerImg: RabelinkLogo,
    imageStyles: 'rounded-md object-cover',
    text: `"Routescanner has opened a new world for us by showcasing new operators and new container shipping routes all over the world.”`,
    hasReviewerImgBg: false
  }
]

const cargoOwnerQuestions = [
  {
    contents: `Routescanner enables complex multi-factor container portfolio optimization. Go beyond reporting and start rethinking your logistics today. Set your own parameters and run design scenarios for continuous optimization.`,
    title: 'Make the right decision when balancing lead time, emissions and costs'
  },
  {
    contents: `The GLEC-Framework (Global Logistics Emissions Council) is the only globally recognized methodology for harmonized calculation and reporting of the logistics GHG footprint across the multi-modal supply chain.`,
    title: 'Report emissions based on the international standard'
  },
  {
    contents: `We have the biggest database of container schedules, updated by operators around the world.  Easily optimize your portfolio through our API or manual upload functionality.`,
    title: 'Easily upload your portfolio or connect with our API'
  }
]

const operatorQuestions = [
  {
    contents: `Share your ocean, barge, or rail schedules and join our neutral, worldwide platform for free. Visualising your network in the largest supply chain optimization platform.`,
    title: 'Increase your online visibility'
  },
  {
    contents: `Use our Direct Connections feature to showcase your entire network on your own website and let your customers  find their best routes with you.`,
    title: 'Showcase your network on your own website'
  },
  {
    contents: `Appear in the search results of cargo owners and freight forwarders that are looking for door-to-door container transport solutions, and receive high-quality quote requests directly.`,
    title: 'Receive high-quality leads'
  }
]

const portQuestions = [
  {
    contents: `Vizualize the inland and seaside network of your port. Our intermodal approach, which includes rail, barge, shortsea and deepsea, is unique. Promote the modal shift by providing your customers actionable insights on the attractiveness of your network.`,
    title: 'Attract more cargo'
  },
  {
    contents: `Leading ports ensure they stand out from the competition by promoting themselves digitally. Grow your website visits with our easy-to-use application and ensure your clients get the most out of your website.`,
    title: 'Digitalise your marketing'
  },
  {
    contents: `Connect your local intermodal container operators to Routescanner. Introduce Routescanner to your local operators, and we will connect them to Routescanner, so the connectivity of your network is completely visible to the world.`,
    title: 'Complete your network'
  }
]

export const BlogData = z.object({
  meta: z.object({
    next_page: z.null(),
    previous_page: z.null(),
    count: z.number()
  }),
  data: z.array(
    z.object({
      title: z.string(),
      slug: z.string(),
      summary: z.string(),
      url: z.string(),
      featured_image: z.string(),
      tags: z.array(z.object({ name: z.string(), slug: z.string() }))
    })
  )
})

export async function loader({ request }: LoaderFunctionArgs) {
  const profile = await getUserProfile(request, { allowUnauthenticated: true })
  const config = await getRoutescannerApiConfig(request)
  const routescannerApi = new RoutescannerApi(config)
  try {
    const metricsResponse = await routescannerApi.fetchMetrics()
    const metricsResult = statsSchema.safeParse(metricsResponse.data)
    const metrics = metricsResult.success
      ? metricsResult.data
      : {
          schedulesCount: 0,
          terminalsCount: 0,
          locodesCount: 0,
          operatorsCount: 0
        }

    return json({
      profile: profile?.profile || null,
      scheduleBuilderUrl: SCHEDULE_BUILDER_FE_URL,
      optimizerD2D: optimizerIntegrationEnabled(profile),
      metrics
    })
  } catch (error) {
    console.error(error)
    return json({
      profile: profile?.profile || null,
      scheduleBuilderUrl: SCHEDULE_BUILDER_FE_URL,
      optimizerD2D: optimizerIntegrationEnabled(profile),
      metrics: {
        schedulesCount: 0,
        terminalsCount: 0,
        locodesCount: 0,
        operatorsCount: 0
      }
    })
  }
}

const solutionCategories = {
  'Cargo Owners & Freight Forwarders': [
    {
      labelText: 'Route Optimizer',
      heading: 'Find better container trade lanes',
      description:
        'Go beyond reporting and start rethinking your logistics today. Set your own preferences across supply chain variables and run scenarios for continuous optimization.',
      icon: <ScaleIcon />,
      id: 1,
      link: '/features/route-optimizer',
      isPro: false
    },
    {
      labelText: 'Emissions Calculation',
      heading: 'Emissions reporting for container logistics',
      description: 'Upload your shipment data, or connect to our API, and receive carbon footprint reports or CO2e values for all of your shipments.',
      icon: <GlobeAltIcon />,
      id: 2,
      link: '/features/emissions-calculation',
      isPro: false
    },
    // {
    //   labelText: 'Door to Door',
    //   heading: 'Find any route, from any address',
    //   description: 'Simply enter your container origin and destination, and we will find your optimal container route from “Door to Door”.',
    //   icon: <HomeModernIcon />,
    //   id: 3,
    //   link: '/features/door-to-door',
    //   isPro: false
    // },
    {
      labelText: 'API connection',
      heading: 'Seamlessly connect with our API',
      description:
        'Analyse your emissions and distance of your container shipments on a continuous basis within your own TMS, ERP or other procurement system.',
      icon: <CommandLineIcon />,
      id: 4,
      link: '/features/distance-emissions-api',
      isPro: false
    }
    // {
    //   labelText: 'Connectivity Comparison',
    //   heading: 'Compare ports, terminals or entire countries',
    //   description:
    //     'With the Connectivity Comparison tool you can compare multiple countries, ports and terminals, among different supply chain factors.',
    //   icon: <NetworkIcon className="relative -top-2 left-3 h-full w-full" />,
    //   id: 4,
    //   link: '/',
    //   isPro: true
    // }
  ],
  'Carriers & Operators': [
    {
      labelText: 'Direct Connections',
      heading: 'Promote your global intermodal network',
      description:
        'Vizualize your entire network. Let cargo owners and forwarders explore container transport options via your port or terminals with Direct Connections.',
      icon: <GlobeAltIcon />,
      id: 1,
      link: '/features/direct-connections',
      isPro: false
    },
    {
      labelText: 'API connection',
      heading: 'Seamlessly connect with our API',
      description:
        'Analyse your emissions and distance of your container shipments on a continuous basis within your own TMS, ERP or other procurement system.',
      icon: <CommandLineIcon />,
      id: 2,
      link: '/features/distance-emissions-api',
      isPro: false
    }
  ],
  'Ports & Terminals': [
    {
      labelText: 'Direct Connections',
      heading: 'Promote your global intermodal network',
      description:
        'Vizualize your entire network. Let cargo owners and forwarders explore container transport options via your port or terminals with Direct Connections.',
      icon: <ArrowRightCircleIcon />,
      id: 1,
      link: '/features/direct-connections',
      isPro: false
    }
  ]
}

const heroSliderOptions = [
  {
    id: 0,
    title: 'Search globally',
    image: Slider1Img
  },
  {
    id: 1,
    title: 'Optimize portfolio',
    image: Slider2Img
  },
  {
    id: 2,
    title: 'Connect to API',
    image: Slider3Img
  }
]

export default function HomePage() {
  const { metrics, profile, scheduleBuilderUrl, optimizerD2D } = useLoaderData<typeof loader>()
  const { slideNumber, setSlideNumber, setPlaySlider } = useSliderInterval()

  return (
    <>
      <Header profile={profile} scheduleBuilderUrl={scheduleBuilderUrl} />

      {!optimizerD2D && (
        <div className="bg-callout-blue-90 py-3 text-center text-sm font-semibold text-white">
          Coming soon: an improved Routescanner experience to enhance your container route planning.{' '}
          <Link to="/news/Shaping-the-Future-of-Logistics-with-Route-Optimization" className="underline underline-offset-4">
            Learn more
          </Link>
          .
        </div>
      )}

      <div className="relative flex flex-col bg-opacity-80 bg-gradient-to-b from-brand-primary-200/80 to-white pb-48 text-center">
        <img
          src={HeroBgImg}
          alt=""
          height={500}
          loading="lazy"
          width={1600}
          className="absolute left-0 top-0 -z-10 hidden h-full w-full object-cover object-bottom md:block"
        />
        <h1 className="z-0 mt-10 text-4xl font-black leading-tight text-brand-secondary lg:mt-20 lg:text-7xl">Find your best container route</h1>
        <p className="z-0 mx-auto mb-8 mt-4 w-11/12 text-base text-brand-secondary-350 lg:w-1/2 lg:text-xl">
          The biggest container logistics platform in the world. Carbon footprint of shipments, visualization of intermodal networks, and faster,
          cheaper and more sustainable trade lanes.
        </p>
      </div>
      <div className="mx-auto -mt-52 mb-10 grid max-w-7xl px-6 lg:-mt-40 lg:mb-8 lg:grid-cols-[2fr_3fr] lg:gap-8">
        <div className="relative rounded-lg bg-white p-5 shadow-md">
          <h5 className="mb-2 text-base text-brand-secondary">Schedules sourced directly from more than {metrics.operatorsCount} operators</h5>
          <SearchVoyagesFormV2 optimizerD2D={optimizerD2D} />
        </div>
        <div className="relative hidden lg:block">
          <Tab.Group selectedIndex={slideNumber} onChange={setSlideNumber}>
            <Tab.List className="absolute bottom-0 left-1/2 z-50 inline-flex -translate-x-1/2 justify-center space-x-1 rounded-full bg-white p-3 shadow-lg">
              {heroSliderOptions.map(slider => (
                <Tab
                  key={slider.id}
                  onMouseDown={() => setPlaySlider(false)}
                  className={({ selected }) =>
                    classNames(
                      'whitespace-nowrap rounded-full px-4 py-2.5 leading-5 text-brand-secondary hover:bg-brand-primary-100 active:bg-brand-primary-350',
                      selected && 'bg-brand-primary-200 font-bold'
                    )
                  }>
                  {slider.title}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {heroSliderOptions.map(slider => (
                <Tab.Panel key={slider.id} className="min-h-[420px] focus:outline-none focus:ring-2">
                  <Transition
                    appear
                    show={slideNumber == slider.id}
                    enter="transition-opacity duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <img src={slider.image} loading="lazy" alt="" />
                  </Transition>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>

      <div className="mx-auto w-full max-w-7xl px-8 py-8 lg:px-8 lg:py-16">
        <LogoCarousel logoList={usersLogoList} />
        <LogoCarousel logoList={operatorsLogoList} direction="right" />
      </div>

      <div className="mx-auto w-full max-w-7xl px-8 py-8 lg:px-8 lg:py-16">
        <div className="grid grid-cols-2 grid-rows-2 lg:grid-cols-4 lg:grid-rows-1">
          <div className="flex w-36 flex-col justify-center text-center lg:w-full">
            <h4 className="text-xl font-black leading-tight text-brand-secondary md:text-4xl">{numberFormatter(metrics.locodesCount)}</h4>
            <h6 className="min-h-16 mt-3 text-lg leading-tight lg:min-h-0">Ports and inland hubs</h6>
          </div>
          <div className="flex w-36 flex-col justify-center text-center lg:w-full">
            <h4 className="text-xl font-black leading-tight text-brand-secondary md:text-4xl">{numberFormatter(metrics.schedulesCount)}</h4>
            <h6 className="min-h-16 mt-3 text-lg leading-tight lg:min-h-0">Schedules</h6>
          </div>
          <div className="flex w-36 flex-col justify-center text-center lg:w-full">
            <h4 className="text-xl font-black leading-tight text-brand-secondary md:text-4xl">{numberFormatter(metrics.terminalsCount)}</h4>
            <h6 className="min-h-16 mt-3 text-lg leading-tight lg:min-h-0">Connected terminals</h6>
          </div>
          <div className="flex w-36 flex-col justify-center text-center lg:w-full">
            <h4 className="text-xl font-black leading-tight text-brand-secondary md:text-4xl">{numberFormatter(metrics.operatorsCount)}</h4>
            <h6 className="min-h-16 mt-3 text-lg leading-tight lg:min-h-0">Operators</h6>
          </div>
        </div>
      </div>
      <div className="pb-12">
        <USPSection data={uspContent} itemHeadingClasses="min-h-16 text-2xl font-black" itemParagraphClasses="my-4" />
      </div>

      <div className="bg-brand-primary-50 py-20">
        <Container>
          <h2 className="mb-8 text-3xl font-black text-brand-tertiary">{'See how Routescanner helps'}</h2>
          <div className="grid gap-8 lg:grid-cols-3">
            {howRoutescannerHelpsContent.map(helpSection => (
              <Link
                to={helpSection.link}
                className="flex flex-col items-start gap-8 rounded-md bg-white shadow-lg hover:shadow-xl"
                key={helpSection.id}>
                <img src={helpSection.img} className="" alt="" />
                <div className="p-6 pt-0">
                  <div>
                    <h4 className="text-lg font-bold text-brand-tertiary-700">{helpSection.labelText}</h4>
                    <h2 className="mt-1 min-h-[4rem] text-2xl font-black text-black">{helpSection.heading}</h2>
                  </div>
                  <div className="mt-6">
                    <p className="min-h-[4rem] text-gray-900">{helpSection.description}</p>
                    <div className="group mt-6 flex items-center gap-2 font-bold text-brand-tertiary transition-colors hover:text-secondary hover:underline">
                      {helpSection.id === 3 ? 'Learn more' : 'Learn more'}
                      <ChevronRightIcon className="h-4 w-4 transition-transform group-hover:translate-x-1" />
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <h2 className="mb-8 mt-20 text-3xl font-black text-brand-tertiary">Get started with our solutions</h2>

          <div className="w-full px-2 py-2 sm:px-0">
            <Tab.Group>
              <Tab.List className="flex space-x-1">
                {Object.keys(solutionCategories).map(category => (
                  <Tab
                    key={category}
                    className={({ selected }) =>
                      classNames(
                        'rounded-md border-2 border-solid border-brand-primary-400 px-4 py-2.5 leading-5 text-brand-secondary',
                        selected && 'border-brand-primary-350 bg-brand-primary-200 font-semibold'
                      )
                    }>
                    {category}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-5">
                {Object.values(solutionCategories).map((posts, idx) => (
                  <Tab.Panel key={idx} className="focus:outline-none focus:ring-2">
                    <ul className="flex flex-col gap-4 md:flex-row">
                      {posts.map(postSingle => (
                        <Link
                          className="flex w-80 flex-col items-start gap-4 rounded-md bg-white p-6 shadow-lg hover:shadow-xl"
                          to={postSingle.link}
                          key={postSingle.id}>
                          <div className="flex w-full justify-between">
                            <div className="flex w-fit items-center rounded-sm bg-brand-primary-100 p-2 opacity-70">
                              <span className="flex h-8 w-8 items-center gap-2 text-brand-tertiary-700" title="">
                                {postSingle.icon}
                              </span>
                            </div>
                            {postSingle.isPro && (
                              <div className="flex h-6 items-center justify-center gap-1 bg-brand-primary-220 px-2 py-1 text-brand-secondary">
                                <span className="text-xs font-bold uppercase">PRO</span>
                              </div>
                            )}
                          </div>
                          <div>
                            <h4 className="text-lg font-bold text-brand-tertiary-700">{postSingle.labelText}</h4>
                            <h2 className="mt-2 text-xl font-black text-black">{postSingle.heading}</h2>
                          </div>
                          <div>
                            <p className="min-h-[8rem] text-gray-900">{postSingle.description}</p>
                            <div className="mt-6 inline-block font-bold text-brand-tertiary transition-colors hover:text-secondary">Learn more</div>
                          </div>
                        </Link>
                      ))}
                    </ul>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </Container>
      </div>

      <div className="lg:py-18 py-10 lg:pt-24">
        <Container>
          <div className="grid grid-cols-12 items-center justify-between">
            <div className="col-span-12 lg:col-span-5">
              <div className="inline-flex h-6 items-center justify-center gap-1 bg-brand-primary-300 px-2 py-1 text-brand-secondary">
                <span className="text-xs font-bold">OUR DATABASE</span>
              </div>
              <h3 className="mb-6 mt-2 text-3xl font-black leading-tight text-black">One routing engine, for continuous supply chain optimization</h3>
              <p className="mt-4 text-lg">
                Routescanner allows operators to share their schedules in one place. So information is always accurate, and accessible. Any missing
                information is supplemented by Routescanner.
              </p>
              <div className="mt-3 flex gap-2 lg:gap-8">
                <div>
                  <h6 className="mb-1 font-bold">Objects</h6>
                  <ul className="m-0 flex list-none flex-col gap-1 p-0">
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      {numberFormatter(metrics.schedulesCount)} schedules
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      {numberFormatter(metrics.operatorsCount)} operators
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      {numberFormatter(metrics.locodesCount)} ports and inland hubs
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      {numberFormatter(metrics.terminalsCount)} terminals
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="mb-1 font-bold">Modalities</h6>
                  <ul className="m-0 flex list-none flex-col gap-1 p-0">
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Deepsea
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Shortsea
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Barge
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Rail
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Truck
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="mb-1 font-bold">Variables</h6>
                  <ul className="m-0 flex list-none flex-col gap-1 p-0">
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Lead time
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Transfers
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Frequency
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Emissions
                    </li>
                    <li className="flex items-center font-bold">
                      <CheckIcon className="mr-2 h-4 w-4 text-brand-primary" />
                      Reliability (beta)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-7 col-start-6 hidden flex-col items-center lg:flex">
              <picture className="hidden px-12 lg:block">
                <img src={NeverStopOptimisingImg} alt="" title="" loading="lazy" className="h-auto max-w-full" />
              </picture>
            </div>
          </div>

          <div className="mt-20 grid gap-8 lg:grid-cols-3">
            <div className="flex flex-col items-start gap-4">
              <h2 className="mt-1 text-2xl font-black text-brand-tertiary">Schedules sourced directly from operators</h2>
              <p className="text-gray-900">
                The only platform to have the complete intermodal services overview. Fully updated daily by sea and inland operators around the world.
              </p>
            </div>
            <div className="flex flex-col items-start gap-4">
              <h2 className="mt-1 text-2xl font-black text-brand-tertiary">GLEC-Accredited Emissions Reporting</h2>
              <p className="text-gray-900">
                Accurate and accredited emission values to support your reporting, based on the standard internationally recognised framework.
              </p>
            </div>
            <div className="flex flex-col items-start gap-4">
              <h2 className="mt-1 text-2xl font-black text-brand-tertiary">Connecting Routes in one user-friendly overview</h2>
              <p className="text-gray-900">
                Our uniquely developed intermodal container routing engine is covering Sea, Rail, Barge & truck, and is easy to use.
              </p>
            </div>
          </div>
        </Container>
      </div>

      <div className="flex flex-1 justify-center">
        <TestimonialCarousel
          showMoreStories={false}
          testimonials={testimonials}
          className="mx-4 h-full max-w-7xl bg-brand-primary-50 p-1 lg:mx-auto lg:mb-2 lg:p-12"
        />
      </div>

      <div className="lg:py-18 -mt-8 py-10 pt-0 lg:mt-0">
        <Container>
          <div className="grid grid-cols-1 items-center gap-16 lg:grid-cols-[1.3fr_2fr]">
            <div className="mt-8">
              <div className="inline-flex h-6 items-center justify-center gap-1 bg-brand-primary-300 px-2 py-1 text-brand-secondary">
                <span className="text-xs font-bold">CARGO OWNERS & FREIGHT FORWARDERS</span>
              </div>
              <h3 className="mb-6 mt-2 text-3xl font-black leading-tight text-black">Find better trade lanes</h3>
              <p className="mt-4 text-lg">
                Actionable, accessible and trustworthy data at your fingertips with our breakthrough intermodal algorithms. Report your emission,
                rethink your supply chain and reduce inefficiencies through continuous supply chain optimization.
              </p>
              <FaqSection
                contentClasses="mb-4 -mt-3 max-w-3xl px-6 pb-3 text-left"
                questions={cargoOwnerQuestions}
                accordionSingleClasses="shadow-sm my-2 hover:shadow-lg"
              />
              <Link
                to="/roles/cargo-owners-freight-forwarders"
                className="btn btn-secondary btn-outline mt-6 inline-flex items-center gap-2 font-bold hover:underline">
                Learn more
                <ChevronRightIcon className="h-4 w-4" />
              </Link>
            </div>
            <div className="flex flex-col items-center">
              <picture className="hidden lg:block">
                <img src={CargoOwnersSectionImg} alt="" title="" loading="lazy" className="h-auto max-w-full" />
              </picture>
            </div>
          </div>
        </Container>
      </div>

      <div className="lg:py-18 py-10">
        <Container>
          <div className="grid grid-cols-1 items-center gap-16 lg:grid-cols-[2fr_1.3fr]">
            <div className="flex flex-col items-center">
              <picture className="hidden lg:block">
                <img src={CarriersSectionImg} alt="" title="" loading="lazy" className="h-auto max-w-full" />
              </picture>
            </div>
            <div className="mt-8">
              <div className="inline-flex h-6 items-center justify-center gap-1 bg-brand-primary-300 px-2 py-1 text-brand-secondary">
                <span className="text-xs font-bold">CARRIERS & OPERATORS</span>
              </div>
              <h3 className="mb-6 mt-2 text-3xl font-black leading-tight text-black">Grow your container business as an operator or carrier</h3>
              <FaqSection
                contentClasses="mb-4 -mt-3 max-w-3xl px-6 pb-3 text-left"
                questions={operatorQuestions}
                accordionSingleClasses="shadow-sm my-2 hover:shadow-lg"
              />
              <Link
                to="/roles/carriers-and-operators"
                className="btn btn-secondary btn-outline mt-6 inline-flex items-center gap-2 font-bold hover:underline">
                Learn more
                <ChevronRightIcon className="h-4 w-4" />
              </Link>
            </div>
          </div>
        </Container>
      </div>

      <div className="lg:py-18 py-10">
        <Container>
          <div className="grid grid-cols-1 items-center gap-16 lg:grid-cols-[1.3fr_2fr]">
            <div className="mt-8">
              <div className="inline-flex h-6 items-center justify-center gap-1 bg-brand-primary-300 px-2 py-1 text-brand-secondary">
                <span className="text-xs font-bold">PORTS & TERMINALS</span>
              </div>
              <h3 className="mb-6 mt-2 text-3xl font-black leading-tight text-black">Promote your intermodal container network</h3>
              <FaqSection
                contentClasses="mb-4 -mt-3 max-w-3xl px-6 pb-3 text-left"
                questions={portQuestions}
                accordionSingleClasses="shadow-sm my-2 hover:shadow-lg"
              />
              <Link
                to="/features/direct-connections"
                className="btn btn-secondary btn-outline mt-6 inline-flex items-center gap-2 font-bold hover:underline">
                Learn more
                <ChevronRightIcon className="h-4 w-4" />
              </Link>
            </div>
            <div className="flex flex-col items-center">
              <picture className="hidden lg:block">
                <img src={PortsSectionImg} alt="" title="" loading="lazy" className="h-auto max-w-full" />
              </picture>
            </div>
          </div>
        </Container>
      </div>
      <div className="lg:py-18 py-10">
        <Container>
          <div className="inline-flex h-6 items-center justify-center gap-1 bg-brand-primary-300 px-2 py-1 text-brand-secondary">
            <span className="text-xs font-bold">OUR COMPLIANCE</span>
          </div>
          <h3 className="mb-6 mt-2 text-3xl font-black leading-tight text-black">How we comply with the international standards</h3>
          <div className="flex grid-cols-3 flex-col gap-8 md:grid">
            <div className="flex flex-col justify-between gap-8 rounded-md border p-4 pb-8">
              <div className="flex flex-col justify-start gap-2 leading-loose">
                <div className="flex flex-row flex-wrap items-center gap-2 py-4">
                  <img className="h-12 w-auto" alt="logo of SIG" src={GLECLogo} />
                  <img className="h-10 w-auto" alt="logo of SIG" src={SFCLogo} />
                </div>
                <div className="text-lg font-black text-black">Glec-Framework 3</div>
                The GLEC-Framework (Global Logistics Emissions Council) is the only globally recognized methodology for harmonized calculation and
                reporting of the logistics GHG footprint across the multimodal supply chain.
              </div>
              <Link to="/glec" className="inline-flex items-center gap-2 font-bold hover:underline">
                Read more about GLEC
                <ChevronRightIcon className="h-4 w-4" />
              </Link>
            </div>

            <div className="flex flex-col justify-between gap-8 rounded-md border p-4 pb-8">
              <div className="flex flex-col justify-start gap-2 leading-loose">
                <div className="flex-cols flex flex-wrap items-center gap-2 py-4">
                  <img className="h-12 w-auto" alt="logo of SIG" src={SIGLogo} />
                </div>
                <div className="text-lg font-black text-black">SIG Neutrality</div>
                SIG (Software Improvement Group) Neutrality Assessment has verified the reliability and trustworthiness of our data, addressing
                potential threats through thorough assessments and control measures.
              </div>
              <Link to="/sig" className="inline-flex items-center gap-2 font-bold hover:underline">
                Read more about SIG
                <ChevronRightIcon className="h-4 w-4" />
              </Link>
            </div>

            <div className="flex flex-col justify-between gap-8 rounded-md border p-4 pb-8">
              <div className="flex flex-col justify-start gap-2 leading-loose">
                <img className="mt-4 h-16 w-16" alt="logo of ISO" src={IsoCertificationLogo} />
                <div className="text-lg font-black text-black">ISO 14083 Certification</div>
                ISO 14083 is an internationally recognized standard for environmental management systems (EMS) and provides universal guidance for the
                quantification and reporting of greenhouse gas (GHG) emissions arising from the operation of transport chains for freight.
              </div>
              <Link to="/ISO-14083" className="inline-flex items-center gap-2 font-bold hover:underline">
                Read more about ISO 14083
                <ChevronRightIcon className="h-4 w-4" />
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <div className="mx-auto w-full max-w-7xl py-20 pb-40 text-center">
        <div className="rounded bg-brand-secondary-500 px-7 py-12 shadow-[0_4rem_8rem_-.5rem_rgba(0,0,0,0.1)] lg:py-24">
          <p className="text-xl text-white">Get started</p>
          <h1 className="relative my-3 text-5xl font-black text-white">Start rethinking your supply chain</h1>
          <a
            href="https://calendly.com/routescanner-sales/routescanner-introduction"
            target="_blank"
            className="mt-4 inline-block bg-primary px-6 py-4 font-bold no-underline hover:bg-white"
            rel="noreferrer">
            Schedule a call
          </a>
        </div>
      </div>
      <Footer />
    </>
  )
}
