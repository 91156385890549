import { CheckIcon } from '@heroicons/react/24/solid'
import * as Tooltip from '@radix-ui/react-tooltip'
import { json, redirect } from '@remix-run/node'
import { useFetcher, useSearchParams } from '@remix-run/react'
import { withZod } from '@remix-validated-form/with-zod'
import classNames from 'classnames'
import { Base64 } from 'js-base64'
import { DateTime } from 'luxon'
import { useMediaQuery } from 'usehooks-ts'
import { zfd } from 'zod-form-data'
import { originAndDestinationShouldNotBeEqual } from '~/schemas/search-schema-validation'
import { getUserProfile } from '~/services/auth.server'
import { optimizerIntegrationEnabled } from '~/services/optimizer-integration-enabled'
import PostHogClient, { getDistinctId } from '~/services/posthog.server'
import { Can } from '~/services/rbac'
import { SuggestionInput } from './fullstack-components.suggestions'
import type { ActionFunctionArgs } from '@remix-run/node'

export const HomeSearchSchema = zfd
  .formData({
    destination: zfd.text(),
    origin: zfd.text(),
    sort: zfd.text(),
    modalities: zfd.repeatableOfType(zfd.text()).refine(val => val.length > 0),
    departure: zfd.text(),
    isMobile: zfd.text().optional()
  })
  .superRefine(originAndDestinationShouldNotBeEqual)
  .transform(val => ({
    departure: val.departure,
    sort: val.sort,
    fromType: val.origin.split('|')[0],
    from: val.origin.split('|')[1],
    fromLabel: val.origin.split('|')[2],
    toType: val.destination.split('|')[0],
    to: val.destination.split('|')[1],
    toLabel: val.destination.split('|')[2],
    limit: '3',
    originsNearby: '1',
    destinationsNearby: '1',
    modalities: val.modalities.toString(),
    voyageIndex: '0',
    isMobile: val.isMobile === 'true'
  }))

export const validator = withZod(HomeSearchSchema)

export async function action({ request }: ActionFunctionArgs) {
  const profile = await getUserProfile(request, { allowUnauthenticated: true })
  const result = await validator.validate(await request.clone().formData())
  if (result.error) {
    return json({ error: result.error })
  }
  const { isMobile, ...params } = result.data

  if (isMobile) {
    return redirect('/app/optimizer/')
  }

  const searchParams = new URLSearchParams(params)

  try {
    const distinctId = await getDistinctId(request)
    const phClient = PostHogClient()
    phClient.capture({
      distinctId: distinctId,
      event: 'optimizer-home-search',
      properties: result.data
    })
  } catch (e) {
    console.error('[search-voyages-v2] Error while capturing event', e)
  }

  if (optimizerIntegrationEnabled(profile)) {
    const origin = {
      id: searchParams.get('from'),
      name: searchParams.get('fromLabel'),
      type: searchParams.get('fromType')
    }

    const destination = {
      id: searchParams.get('to'),
      name: searchParams.get('toLabel'),
      type: searchParams.get('toType')
    }

    const serializeOriginDestination = Base64.encode(
      JSON.stringify({
        origin,
        destination
      })
    )

    const profile = searchParams.get('sort') === 'duration' ? '&profile=DURATION' : searchParams.get('sort') === 'arrival' ? '' : '&profile=EMISSIONS'

    const modalitiesString = searchParams.get('modalities')
    let modalitiesUrl = ''
    if (modalitiesString) {
      const modalities = modalitiesString.split(',').map(m => m.toUpperCase())
      const disallowedModalities = ['SEA', 'RAIL', 'TRUCK', 'BARGE'].filter(m => !modalities.includes(m))
      modalitiesUrl = disallowedModalities.map(m => `&excludeModalities[]=${m}`).join('')
    }

    return redirect(`/app/optimizer/search?origin-destination=${serializeOriginDestination}${profile}${modalitiesUrl}`)
  } else {
    return redirect(`/app/voyages?${searchParams.toString()}`)
  }
}

export function SearchVoyagesFormV2({ optimizerD2D }: { optimizerD2D?: boolean }) {
  const [searchParams] = useSearchParams()
  const searchFetcher = useFetcher<typeof action>()

  return (
    <searchFetcher.Form
      method="post"
      action="/fullstack-components/search-voyages-v2"
      autoComplete="off"
      className="grid gap-y-4 rounded-sm bg-white text-sm">
      <div className="flex flex-col items-center justify-between gap-x-4 lg:flex-row">
        <div className="w-full py-1 lg:py-0">
          <SuggestionInput
            label="Origin"
            type="origin"
            error={!!searchFetcher.data?.error.fieldErrors['origin']}
            disabled={searchFetcher.state === 'loading' || searchFetcher.state === 'submitting'}
            inputClassName="w-full border p-4 input input-bordered"
          />
        </div>
        <div className="w-full py-1 lg:py-0">
          <SuggestionInput
            label="Destination"
            type="destination"
            error={!!searchFetcher.data?.error.fieldErrors['destination']}
            disabled={searchFetcher.state === 'loading' || searchFetcher.state === 'submitting'}
            inputClassName="w-full border p-4 input input-bordered"
          />
        </div>
      </div>

      <input
        type="date"
        className={classNames(
          'input input-sm h-[38px] px-1 focus:outline-none',
          searchFetcher.data?.error.fieldErrors['date'] && 'rounded-sm border border-red-500'
        )}
        hidden
        id="departure"
        name="departure"
        defaultValue={searchParams.get('departure') || DateTime.now().toISODate()}
        min={DateTime.now().toISODate()}
        max={DateTime.now().plus({ months: 5 }).toISODate()}></input>
      <Can I="read" a="optimizer" passThrough>
        {authorized => {
          return authorized || !optimizerD2D ? (
            <div className="flex flex-col gap-2 py-1 lg:py-0">
              <label htmlFor="modalities" className="font-bold">
                Modality
              </label>
              <fieldset
                className={classNames(
                  'mt-1 flex items-center justify-start gap-3',
                  searchFetcher.data?.error.fieldErrors['modalities'] && 'rounded-sm border border-red-500'
                )}>
                <div className="flex items-center gap-2">
                  <input type="checkbox" defaultChecked id="sea" value="sea" className="peer hidden" name="modalities" />
                  <label
                    htmlFor="sea"
                    className="relative mr-8 flex h-6 w-6 cursor-pointer items-center justify-between border border-gray-200 bg-white after:invisible after:absolute after:left-1 after:top-1.5 after:h-2 after:w-3 after:rotate-[125deg] after:transform after:border-r-2 after:border-t-2 after:border-gray-900 peer-checked:text-gray-600 peer-checked:after:visible hover:border-gray-900 hover:bg-gray-50 hover:text-gray-600">
                    <span className="label-text ml-7 pl-1">Sea</span>
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input type="checkbox" defaultChecked id="rail" value="rail" className="peer hidden" name="modalities" />
                  <label
                    htmlFor="rail"
                    className="relative mr-8 flex h-6 w-6 cursor-pointer items-center justify-between border border-gray-200 bg-white after:invisible after:absolute after:left-1 after:top-1.5 after:h-2 after:w-3 after:rotate-[125deg] after:transform after:border-r-2 after:border-t-2 after:border-gray-900 peer-checked:text-gray-600 peer-checked:after:visible hover:border-gray-900 hover:bg-gray-50 hover:text-gray-600">
                    <span className="label-text ml-7 pl-1">Rail</span>
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input type="checkbox" defaultChecked id="barge" value="barge" className="peer hidden" name="modalities" />
                  <label
                    htmlFor="barge"
                    className="relative mr-11 flex h-6 w-6 cursor-pointer items-center justify-between border border-gray-200 bg-white after:invisible after:absolute after:left-1 after:top-1.5 after:h-2 after:w-3 after:rotate-[125deg] after:transform after:border-r-2 after:border-t-2 after:border-gray-900 peer-checked:text-gray-600 peer-checked:after:visible hover:border-gray-900 hover:bg-gray-50 hover:text-gray-600">
                    <span className="label-text ml-7 pl-1">Barge</span>
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input type="checkbox" defaultChecked id="truck" value="truck" className="peer hidden" name="modalities" />
                  <label
                    htmlFor="truck"
                    className="relative mr-8 flex h-6 w-6 cursor-pointer items-center justify-between border border-gray-200 bg-white after:invisible after:absolute after:left-1 after:top-1.5 after:h-2 after:w-3 after:rotate-[125deg] after:transform after:border-r-2 after:border-t-2 after:border-gray-900 peer-checked:text-gray-600 peer-checked:after:visible hover:border-gray-900 hover:bg-gray-50 hover:text-gray-600">
                    <span className="label-text ml-7 pl-1">Truck</span>
                  </label>
                </div>
              </fieldset>
            </div>
          ) : (
            <input type="hidden" name="modalities" value="sea,rail,barge,truck" />
          )
        }}
      </Can>
      <div className="flex flex-col gap-2 py-1 lg:py-0">
        <label htmlFor="sort" className="font-bold">
          Sort on
        </label>
        <Can I="read" a="optimizer" passThrough>
          {authorized => {
            const showAllOptions = authorized || !optimizerD2D
            return (
              <fieldset
                className={classNames(
                  'grid w-full',
                  {
                    'grid-cols-3': showAllOptions,
                    'grid-cols-2': !showAllOptions
                  },
                  searchFetcher.data?.error.fieldErrors['sort'] && 'border border-red-500'
                )}>
                <div>
                  <input type="radio" defaultChecked id="emission_co2" name="sort" value="emission_co2" className="peer hidden" />
                  <label
                    htmlFor="emission_co2"
                    className="peer-chcked:bg-brand-primary-200 flex cursor-pointer items-center justify-center rounded-bl-sm rounded-tl-sm border border-brand-primary-350 bg-white px-2 py-1 text-center text-brand-secondary peer-checked:bg-brand-primary-200 peer-checked:font-bold peer-active:bg-brand-primary-300 hover:bg-brand-primary-50 lg:p-3">
                    Emissions
                  </label>
                </div>
                <div>
                  <input type="radio" id="duration" name="sort" value="duration" className="peer hidden" />
                  <label
                    htmlFor="duration"
                    className="peer-chcked:bg-brand-primary-200 -ml-[1px] flex cursor-pointer items-center justify-center border border-brand-primary-350 bg-white px-2 py-1 text-center text-brand-secondary peer-checked:bg-brand-primary-200 peer-checked:font-bold peer-active:bg-brand-primary-300 hover:bg-brand-primary-50 lg:p-3">
                    Lead time
                  </label>
                </div>
                {showAllOptions && (
                  <div>
                    <input type="radio" id="arrival" name="sort" value="arrival" className="peer hidden" />
                    <label
                      htmlFor="arrival"
                      className="peer-chcked:bg-brand-primary-200 -ml-[2px] flex cursor-pointer items-center justify-center rounded-br-sm rounded-tr-sm border border-brand-primary-350 bg-white px-2 py-1 text-center text-brand-secondary peer-checked:bg-brand-primary-200 peer-checked:font-bold peer-active:bg-brand-primary-300 hover:bg-brand-primary-50 lg:p-3">
                      <span className="hidden lg:inline-block">{optimizerD2D ? 'Balanced' : 'Earliest arrival'}</span>
                      <span className="lg:hidden">{optimizerD2D ? 'Balanced' : 'Arrival'}</span>
                    </label>
                  </div>
                )}
              </fieldset>
            )
          }}
        </Can>
      </div>
      <input type="hidden" name="isMobile" value={useMediaQuery('(max-width: 1024px)').toString()} />
      <div className="w-full">
        <button type="submit" className="btn btn-primary w-full rounded-sm px-10">
          {searchFetcher.state === 'loading' ? <span className="loading"></span> : 'Search'}
        </button>
      </div>
      <div className="hidden flex-row justify-between px-2 md:flex">
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger type="button">
              <div className="flex items-center border-b border-dashed border-gray-400">
                <CheckIcon className="mr-1 h-4 w-4 text-brand-primary" />
                GLEC-accredited
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="max-w-xs rounded-md bg-brand-secondary-600 px-2 py-1 text-white">
                Emissions are calculated with GLEC v3. We are GLEC-accreditated by the Smart Freight Centre.
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger type="button">
              <div className="flex items-center border-b border-dashed border-gray-400">
                <CheckIcon className="mr-1 h-4 w-4 text-brand-primary" />
                ISO-verified
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="max-w-xs rounded-md bg-brand-secondary-600 px-2 py-1 text-white">
                Our emission calculations are verified to be in accordance with ISO 14083.
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger type="button">
              <div className="flex items-center border-b border-dashed border-gray-400">
                <CheckIcon className="mr-1 h-4 w-4 text-brand-primary" />
                SIG-neutrality
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="max-w-xs rounded-md bg-brand-secondary-600 px-2 py-1 text-white">
                Our data and algorithms have been confirmed to be neutral by the Software Improvement Group - SIG.
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
    </searchFetcher.Form>
  )
}
